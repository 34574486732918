@font-face {
  font-family: "Neue Montreal book";
  src: url(./fonts/NeueMontreal/PPNeueMontreal-Free\ for\ personal\ use\ v2.4/PPNeueMontreal-Book.otf);
  font-weight: book;
}
@font-face {
  font-family: "Neue Montreal bold";
  src: url(./fonts/NeueMontreal/PPNeueMontreal-Free\ for\ personal\ use\ v2.4/PPNeueMontreal-Bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: "Neue Montreal italic";
  src: url(./fonts/NeueMontreal/PPNeueMontreal-Free\ for\ personal\ use\ v2.4/PPNeueMontreal-Italic.otf);
  font-weight: italic;
}
@font-face {
  font-family: "Neue Montreal medium";
  src: url(./fonts/NeueMontreal/PPNeueMontreal-Free\ for\ personal\ use\ v2.4/PPNeueMontreal-Medium.otf);
  font-weight: medium;
}
@font-face {
  font-family: "Neue Montreal SemiBolditalic";
  src: url(./fonts/NeueMontreal/PPNeueMontreal-Free\ for\ personal\ use\ v2.4/PPNeueMontreal-SemiBolditalic.otf);
  font-weight: SemiBolditalic;
}
@font-face {
  font-family: "Neue Montreal thin";
  src: url(./fonts/NeueMontreal/PPNeueMontreal-Free\ for\ personal\ use\ v2.4/PPNeueMontreal-Thin.otf);
  font-weight: thin;
}

@media screen and (max-width: 1000px) {
  .img-col {
    display: none;
  }
}
html,
body {
  max-width: 100vw !important;
  overflow-x: hidden !important;
}

h1 {
  font-family: "Neue Montreal bold";
}

:root {
  --color1: #fe4744;
}
Canvas {
  position: absolute;
}

* {
  outline: 0px solid red;
  font-family: "Neue Montreal book", sans-serif;
  font-weight: book;
  z-index: 5;
}

.app {
}
section {
  z-index: 7;
}
.resume_button {
  border-radius: 30px;
  background-color: var(--color1) !important ;
}

.nav-bar {
  width: 100vw;
  box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
  backdrop-filter: blur(2px);
  background-color: rgba(17, 17, 17, 0.427);
  position: fixed;
  z-index: 1;
  color: white;

  /* Rectangle 3 */
}

nav {
  overflow: visible !important;
}

header {
  z-index: -10 !important;
}

.nav_button {
  border-radius: 0% !important;
}
.nav_button:hover {
  background-color: var(--color1) !important ;
  outline: none;
  border-radius: 0%;
}

.landing-section {
  height: 100vh;
}

.blacktext {
  color: black !important;
}

.mosepointer {
  cursor: pointer;
}

.name-section {
  position: absolute;
  top: 30%;
}
.tech-logo {
  transition: all 0.2s;
}
.tech-logo:hover {
  transform: scale(1.3);
}

.wrap {
  width: fit-content;
}

.project-tittle {
  border-bottom: 4px solid #ff0000;
  border-radius: 4px;
}

.tech-section {
  min-height: 70vh;
}
.projects-section {
  min-height: 95vh;
}

.project-card {
  transition: all 0.5s;
  cursor: pointer;
}

.project-card:hover {
  transform: scale(1.1);
}

.tsparticles {
}

footer {
  min-height: 30vh;
  width: 100vw !important;
  background-color: cornflowerblue;
  z-index: 5;
}

.aboutme-content {
  height: 30vh;

  position: absolute;
}

.card-img-top {
  object-fit: cover;
}

.card {
  min-width: 300px;
}

.tag {
  background-color: rgba(128, 128, 128, 0.705);
  padding: 5px;
  border-radius: 3px;
  color: white;
  margin-right: 3px;
  margin-bottom: 10px;
  white-space: nowrap;
  line-height: 30px;
  font-size: small;
}

#tsparticles {
  width: 100%;
}

.contactform-container {
  margin: 0;
}

.resume-link {
  text-decoration: none;
}

.form-control {
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
  text-decoration: none !important;
}

a:hover,
a:focus {
  text-decoration: none;
}

footer * {
  color: rgb(255, 255, 255) !important;
}

.red {
  background-color: var(--color1) !important;
}

.button-wrapper {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	text-align: center !important;
}

.button-wrapper .btn {
	margin: 0 auto !important;
}
